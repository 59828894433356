/* src/components/SelectStakeMethod.js */
import React from "react";
import { useNavigate } from "react-router-dom";
import "./SelectStakeMethod.css";

function SelectStakeMethod() {
  const navigate = useNavigate();

  const handleNavigateToStake = () => {
    navigate("/stake");
  };

  return (
    <div className="select-stake-method-container">
      <div className="info-section">
        <h1 className="page-title">Select Your Staking Method</h1>
        <p className="page-description">
          <strong>How Your aUSD earns interest :</strong>
          <br />
          80% of exchanged USDC is deployed into high-yield strategies, maximizing returns for aUSD stakers.
          <br />
          Stakers receive exclusive yields, resulting in a boosted APY since only a portion of aUSD holders choose to stake.
          <br />
          {/* Updated Span to Anchor Tag */}
          <a
            href="https://agio-protocol-1.gitbook.io/agio-docs/usdausd/stake-usdausd-earn-leveraged-real-yield"
            target="_blank"
            rel="noopener noreferrer"
            className="learn-more"
          >
            Click to learn more about how we boost your staking rewards!
          </a>
        </p>
      </div>

      <div className="staking-cards-container">
        {/* Staking Card for Abracadabra Strategy */}
        <div className="staking-card abracadabra-card" onClick={handleNavigateToStake}>
          <h2 className="card-title">Stake aUSD - Earn ARB & SPELL</h2>
          <p className="card-details">
            Stake aUSD on our platform to earn USDC, generated by converting ARB and SPELL yield rewards from Abracadabra.money
          </p>
          <div className="apy-section">
            <div className="apy-info">
              <span className="apy-label">Base APY (abracadabra.money) :</span> <span className="apy-value">15%-25%</span>
            </div>
            <div className="apy-info">
              <span className="apy-label">Boosted APY (staked aUSD) :</span> <span className="apy-value boosted-apy">38% - 190%</span>
            </div>
          </div>
          <button className="card-button">Stake Now</button>
        </div>

        {/* Additional Card for Future Yield Strategies */}
        <div className="staking-card coming-soon-card">
          <h2 className="card-title">More Yielding Strategies Coming Soon!</h2>
          <p className="card-details">
            We're working on bringing new yield opportunities. Stay tuned!
          </p>
          <button className="card-button" disabled>
            Explore More
          </button>
        </div>
      </div>
    </div>
  );
}

export default SelectStakeMethod;
