import "./App.css";
import Header from "./components/Header";
import LockInfo from "./components/LockInfo";
import Swap from "./components/Swap";
import Tokens from "./components/Tokens";
import Stake from "./components/Stake";
import SelectStakeMethod from "./components/SelectStakeMethod"; // Updated to include your new component
import { Routes, Route } from "react-router-dom";
import { useConnect, useAccount } from "wagmi";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import React, { useState } from "react";

function App() {
  const { address, isConnected } = useAccount();
  const { connect } = useConnect({
    connector: new MetaMaskConnector(),
  });

  // Points state
  const [points, setPoints] = useState(0);

  return (
    <div className="App">
      <Header connect={connect} isConnected={isConnected} address={address} points={points} />
      <div className="mainWindow">
        <Routes>
          <Route
            path="/"
            element={<Swap isConnected={isConnected} address={address} />}
          />
          <Route path="/tokens" element={<Tokens />} />
          <Route path="/lockinfo" element={<LockInfo />} />
          <Route
            path="/stake"
            element={
              <Stake
                isConnected={isConnected}
                address={address}
                setPoints={setPoints}
              />
            } 
          />
          <Route
            path="/select-stake-method"
            element={<SelectStakeMethod />}
          />
        </Routes>
      </div>
    </div>
  );
}

export default App;
