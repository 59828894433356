import React, { useState, useEffect, useCallback } from "react";
import { Input, message, Select, Slider, Row, Col, Button } from "antd";
import { ethers } from 'ethers';
import tokenList from "../tokenList.json";

const { Option } = Select;

function Swap(props) {
  const { address, isConnected } = props;
  const [messageApi, contextHolder] = message.useMessage();
  const [tokenOneAmount, setTokenOneAmount] = useState("");
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [ethBalance, setEthBalance] = useState("");
  const [lockTime, setLockTime] = useState(3600);
  const [expirationTime, setExpirationTime] = useState(86400);
  const [feeRate, setFeeRate] = useState(0);
  const [manualInput, setManualInput] = useState(false);
  const [manualLockTime, setManualLockTime] = useState("");
  const [manualExpirationTime, setManualExpirationTime] = useState("");
  const [isCorrectNetwork, setIsCorrectNetwork] = useState(false);

  const contractAddress = "0x16d5e1656a3F55a80Ced59b22D0CDBD91D83a8Ad"; // CETH contract address
  const ARBITRUM_CHAIN_ID = "0xa4b1"; // Arbitrum One network chain ID

  const contractAbi = [
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "lockPeriod",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "expirationPeriod",
          "type": "uint256"
        }
      ],
      "name": "calculateFeeRate",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "lockPeriod",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "expirationPeriod",
          "type": "uint256"
        }
      ],
      "name": "swapEthForCETH",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
    }
  ];

  const checkNetwork = useCallback(async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const { chainId } = await provider.getNetwork();
      if (chainId === parseInt(ARBITRUM_CHAIN_ID, 16)) {
        setIsCorrectNetwork(true);
      } else {
        setIsCorrectNetwork(false);
        messageApi.open({
          type: 'warning',
          content: "Please switch to the Arbitrum network to perform the swap, and refresh the page once you've switched.",
          duration: 0, // Persist until manually closed
          key: "network_warning"
        });
      }
    } catch (error) {
      console.error("Network check failed:", error);
      setIsCorrectNetwork(false);
    }
  }, [messageApi]);

  useEffect(() => {
    if (isConnected) {
      checkNetwork();
    }
  }, [isConnected, checkNetwork]);

  const fetchFeeRate = useCallback(async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(contractAddress, contractAbi, provider);
      const rate = await contract.calculateFeeRate(lockTime, expirationTime);
      setFeeRate(rate.toNumber());
    } catch (error) {
      console.error('Failed to fetch fee rate:', error);
    }
  }, [lockTime, expirationTime]);

  const fetchEthBalance = useCallback(async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const balance = await provider.getBalance(address);
    setEthBalance(ethers.utils.formatEther(balance).slice(0, ethers.utils.formatEther(balance).indexOf('.') + 4));
  }, [address]);

  useEffect(() => {
    fetchFeeRate();
  }, [lockTime, expirationTime, fetchFeeRate]);

  useEffect(() => {
    if (selectedAsset === 'ETH' && isConnected) {
      fetchEthBalance();
    }
  }, [selectedAsset, address, isConnected, fetchEthBalance]);

  function changeAmount(e) {
    const newAmount = e.target.value;
    if (!/^\d*\.?\d*$/.test(newAmount)) {
      setTokenOneAmount("");
      return;
    }
    setTokenOneAmount(newAmount);
  }

  function handleAssetChange(value) {
    setSelectedAsset(value);
  }

  function isSwapButtonEnabled() {
    return !!tokenOneAmount && selectedAsset === 'ETH' && isCorrectNetwork;
  }

  async function swapEthForCETH() {
    try {
      if (!isCorrectNetwork) {
        throw new Error("You must be connected to the Arbitrum network to perform this swap.");
      }

      if (!tokenOneAmount || parseFloat(tokenOneAmount) <= 0) {
        throw new Error("Please enter a valid ETH amount");
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractAbi, signer);

      const transaction = await contract.swapEthForCETH(lockTime, expirationTime, { value: ethers.utils.parseEther(tokenOneAmount) });
      await transaction.wait();
      messageApi.success('Swap successful!');
      fetchEthBalance();
    } catch (error) {
      console.error('Swap failed:', error);
      if (error.message) {
        messageApi.error(`Swap failed. ${error.message}`);
      } else {
        messageApi.error('Swap failed. Check the console for details.');
      }
    }
  }

  function renderOutputValue() {
    if (selectedAsset === 'ETH' && tokenOneAmount) {
      const ethAmount = parseFloat(tokenOneAmount);
      const fee = (ethAmount * feeRate) / 10000;
      const cethAfterFee = ethAmount - fee;
      return cethAfterFee > 0 ? `${cethAfterFee.toFixed(6)} CETH` : '0.000000 CETH';
    } else if (selectedAsset === 'AGIO') {
      const convertedAmount = tokenOneAmount;
      return `${convertedAmount} cAGIO`;
    } else {
      return '';
    }
  }

  const formatTime = (seconds) => {
    if (seconds < 3600) {
      return `${Math.round(seconds / 60)} min`;
    } else if (seconds < 86400) {
      return `${Math.round(seconds / 3600)} hr`;
    } else {
      return `${Math.round(seconds / 86400)} days`;
    }
  };

  const getSliderStep = (value) => {
    if (value < 3600) {
      return 60; // 1 minute
    } else if (value < 86400) {
      return 3600; // 1 hour
    } else {
      return 86400; // 1 day
    }
  };

  const handleManualInputToggle = () => {
    setManualInput(!manualInput);
  };

  const handleManualLockTimeChange = (e) => {
    setManualLockTime(e.target.value);
  };

  const handleManualExpirationTimeChange = (e) => {
    setManualExpirationTime(e.target.value);
  };

  const applyManualTimes = () => {
    const lockTimeInSeconds = parseInt(manualLockTime) * 3600;
    const expirationTimeInSeconds = parseInt(manualExpirationTime) * 3600;
    if (!isNaN(lockTimeInSeconds) && lockTimeInSeconds >= 3600 && !isNaN(expirationTimeInSeconds) && expirationTimeInSeconds >= 86400) {
      setLockTime(lockTimeInSeconds);
      setExpirationTime(expirationTimeInSeconds);
    } else {
      messageApi.error('Please enter valid times. Lock time must be at least 1 hour and expiration time at least 1 day.');
    }
  };

  return (
    <>
      {contextHolder}
      <div className="tradeBox">
        <h2>Swap to Covered Assets</h2>
        <div className="swapBox">
          <div className="balanceInfo">
            {selectedAsset === 'ETH' && ethBalance && (
              <div className="infoLabel">
                Balance: {ethBalance} ETH
              </div>
            )}
          </div>
          <div className="inputWithTokenChoice">
            <Input
              placeholder="0.0"
              value={tokenOneAmount}
              onChange={changeAmount}
              className="amountInput"
            />
            <Select
              placeholder="Select Asset"
              onChange={handleAssetChange}
              value={selectedAsset}
              className="tokenselection"
            >
              {tokenList.map((token) => (
                <Option key={token.address} value={token.ticker}>
                  <div className="tokenlogo">
                    <img src={token.img} alt={token.ticker} style={{ marginRight: 8, width: 20, height: 20 }} />
                    {token.ticker}
                  </div>
                </Option>
              ))}
            </Select>
          </div>
          <div className="sliderContainer" style={{ marginTop: 4 }}>
            <Row gutter={16}>
              <Col span={24}>
                <h4>Lock Time: {formatTime(lockTime)}</h4>
                <Slider
                  min={60}
                  max={31536000}
                  step={getSliderStep(lockTime)}
                  onChange={(value) => setLockTime(value)}
                  value={lockTime}
                  tooltipVisible
                />
              </Col>
              <Col span={24} style={{ marginTop: 4 }}>
                <h4>Expiration Time: {formatTime(expirationTime)}</h4>
                <Slider
                  min={3600}
                  max={31536000}
                  step={getSliderStep(expirationTime)}
                  onChange={(value) => setExpirationTime(value)}
                  value={expirationTime}
                  tooltipVisible
                />
              </Col>
            </Row>
            <Button onClick={handleManualInputToggle} style={{ marginTop: 8 }}>
              {manualInput ? "Hide Manual Input" : "Show Manual Input"}
            </Button>
            {manualInput && (
              <div style={{ marginTop: 8 }}>
                <Input
                  placeholder="Lock Time in hours"
                  value={manualLockTime}
                  onChange={handleManualLockTimeChange}
                  style={{ marginBottom: 8 }}
                />
                <Input
                  placeholder="Expiration Time in hours"
                  value={manualExpirationTime}
                  onChange={handleManualExpirationTimeChange}
                  style={{ marginBottom: 8 }}
                />
                <Button type="primary" onClick={applyManualTimes}>Apply</Button>
              </div>
            )}
          </div>
          <div className="receiveAmount">
            <div className="infoLabel">You will receive (Fee: {(feeRate / 100).toFixed(2)}%):</div>
            <Input
              readOnly
              value={renderOutputValue()}
              style={{ marginBottom: 16 }}
            />
            <button className="swapButton" onClick={swapEthForCETH} disabled={!isSwapButtonEnabled()}>
              Cover
            </button>
          </div>
          <div className="lockTimeMessage">
            {lockTime && <p>You can redeem cETH back to ETH after the lock time of {formatTime(lockTime)}.</p>}
          </div>
        </div>
      </div>
    </>
  );
}

export default Swap;
