import React from "react"; 
import Logo from "../AGIO-LOGO-A.svg";
import ArbitrumLogo from "../arbitrum_logo_fb8ebaca35.svg";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";  // Import Tooltip from Ant Design

function Header(props) {
  const { address, isConnected, connect, points } = props;

  return (
    <header>
      <div className="leftH">
        <img src={Logo} alt="logo" className="logo" />
        <Link to="/" className="link">
          <div className="headerItem">Swap</div>
        </Link>
        <Link to="/tokens" className="link">
          <div className="headerItem">Redeem</div>
        </Link>
        <Link to="/lockinfo" className="link">
          <div className="headerItem">Your Financial Options</div>
        </Link>
        <Tooltip title="Stake aUSD to earn interest on your aUSD holdings." placement="bottom">
          <Link to="/select-stake-method" className="link">
            <div className="headerItem">Stake aUSD</div>
          </Link>
        </Tooltip>
      </div>
      <div className="rightH">
        <Tooltip title="Agio Points can be redeemed for future airdrops, exclusive access to our launchpad, financial options, and a variety of other rewards." placement="bottom">
          <div className="headerItem pointsDisplay">
            Agio Points: {points ? points : 0} {/* Display points; default to 0 if undefined */}
          </div>
        </Tooltip>
        <div className="headerItem">
          <img src={ArbitrumLogo} alt="Arbitrum" className="eth" />
          Arbitrum
        </div>
        <Tooltip title="Connect to your wallet" placement="bottom">
          <div
            className="connectButton"
            onClick={connect}
            onKeyPress={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                connect();
              }
            }}
            tabIndex={0} // Make the button focusable
            role="button" // ARIA role for accessibility
          >
            {isConnected ? `${address.slice(0, 4)}...${address.slice(-4)}` : "Connect"}
          </div>
        </Tooltip>
      </div>
    </header>
  );
}

export default Header;