/* src/components/Stake.js */
import usdcLogo from '../assets/images/usd-coin-usdc-logo.png';
import Logo from "../AGIO-LOGO-A.svg";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Input, message, Button, Slider, Tooltip, notification, Steps } from "antd";
import { InfoCircleOutlined, ArrowRightOutlined, ReloadOutlined } from "@ant-design/icons";
import { ethers } from "ethers";
import "./Stake.css";

const { Step } = Steps;

function Stake(props) {
  const { address, isConnected, setPoints } = props;
  const [messageApi, contextHolder] = message.useMessage();
  const [stakeAmount, setStakeAmount] = useState("");
  const [unstakeAmount, setUnstakeAmount] = useState("");
  const [lockDuration, setLockDuration] = useState(0);
  const [multiplier, setMultiplier] = useState(1.0);
  const [totalStaked, setTotalStaked] = useState("0.0");
  const [yieldEarned, setYieldEarned] = useState("0.0");
  const [baseApr, setBaseApr] = useState(0);
  const [adjustedApr, setAdjustedApr] = useState(0);
  const [averageYieldBoost, setAverageYieldBoost] = useState(1.0);
  const [isCorrectNetwork, setIsCorrectNetwork] = useState(false);
  const [isStakeView, setIsStakeView] = useState(true);
  const [isUnstakeView, setIsUnstakeView] = useState(false);
  const [isReferView, setIsReferView] = useState(false);
  const [inputReferralCode, setInputReferralCode] = useState("");
  const [userReferralCode, setUserReferralCode] = useState("");
  const [externalReferralCodeUsed, setExternalReferralCodeUsed] = useState("");
  const [externalReferralAddress, setExternalReferralAddress] = useState("");
  const [externalReferralCode, setExternalReferralCode] = useState("");
  const [isReferralSet, setIsReferralSet] = useState(false);
  const [ausdBalance, setAusdBalance] = useState("0.0");
  const [stakedBalance, setStakedBalance] = useState("0.0");
  const [boostMultipliers, setBoostMultipliers] = useState({});
  const [isApproved, setIsApproved] = useState(false);
  const [isUpdatingYield, setIsUpdatingYield] = useState(false);

  const ARBITRUM_CHAIN_ID = 42161; // Arbitrum One Mainnet Chain ID

  const agioPointsContractAddress = "0xAdFd81edAC5eEDE56EC1137F375FB70cd7d3A805";
  const stakingContractAddress = "0xE2C2a3FE34e48fbF0d63F00B4B2cB1Cb7095AAe7";
  const aUSDContractAddress = "0x28d5116b52eF0668Aa75cAF51f79840999DcCDdd";
  const manualAgioPointsAwardContractAddress = "0xc2db9deB5308CCcBe71e073948d335943856A2C4"; // Replace with your actual contract address

// Replace with your Manual Agio Points contract ABI
const manualAgioPointsAbi = useMemo(() => [
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "user",
        "type": "address"
      }
    ],
    "name": "getPoints",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  }
], []);



  // Replace with your staking contract ABI
  const stakingContractAbi = useMemo(() => [
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "addUSDCToRedeemed",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "addUSDCToYield",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "claimYield",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "renounceOwnership",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_aUSD",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "_USDC",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "initialOwner",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "_yieldReleasePercentage",
          "type": "uint256"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "user",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "ausdAmount",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "usdcAmount",
          "type": "uint256"
        }
      ],
      "name": "AUSDForUSDCSwapped",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "previousOwner",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "newOwner",
          "type": "address"
        }
      ],
      "name": "OwnershipTransferred",
      "type": "event"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_agioPoints",
          "type": "address"
        }
      ],
      "name": "setAgioPointsContract",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_defaultMultiplier",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_boostMultiplier30",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_boostMultiplier180",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_boostMultiplier365",
          "type": "uint256"
        }
      ],
      "name": "setBoostMultipliers",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_sourceAPR",
          "type": "uint256"
        }
      ],
      "name": "setSourceAPR",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_treasuryUSDC",
          "type": "uint256"
        }
      ],
      "name": "setTreasuryUSDC",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "newPercentage",
          "type": "uint256"
        }
      ],
      "name": "setYieldReleasePercentage",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "lockDuration",
          "type": "uint256"
        }
      ],
      "name": "stake",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "user",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "lockDuration",
          "type": "uint256"
        }
      ],
      "name": "Staked",
      "type": "event"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "swapAUSDForUSDC",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "newOwner",
          "type": "address"
        }
      ],
      "name": "transferOwnership",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "unstake",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "unstakeAllEligible",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "updatePendingYield",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "USDCAddedToRedeemed",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "USDCAddedToYield",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "user",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "Unstaked",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "user",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "YieldClaimed",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "YieldGenerated",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "YieldReleased",
      "type": "event"
    },
    {
      "inputs": [],
      "name": "accUSDCPerShare",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "agioPoints",
      "outputs": [
        {
          "internalType": "contract IAgioPoints",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "aUSD",
      "outputs": [
        {
          "internalType": "contract IERC20",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "boostMultiplier180",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "boostMultiplier30",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "boostMultiplier365",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "defaultMultiplier",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getAPR",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "user",
          "type": "address"
        }
      ],
      "name": "getAvailableToUnstake",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_user",
          "type": "address"
        }
      ],
      "name": "getUserAverageYieldBoost",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "user",
          "type": "address"
        }
      ],
      "name": "getUserTotalStaked",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "lastYieldReleaseTime",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "owner",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_user",
          "type": "address"
        }
      ],
      "name": "pendingYield",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "redeemedUSDCBalance",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "sourceAPR",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "totalStaked",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "treasuryUSDC",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "USDC",
      "outputs": [
        {
          "internalType": "contract IERC20",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "userStakes",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "stakedAmount",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "rewardDebt",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "lockEndTime",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "lockDuration",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "yieldBufferBalance",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "yieldReleasePercentage",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "yieldUSDCBalance",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
  ], []);

  // Replace with your Agio Points contract ABI
  const agioPointsAbi = useMemo(() => [
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "staker",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "lockDuration",
          "type": "uint256"
        }
      ],
      "name": "awardPointsForStaking",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "code",
          "type": "string"
        }
      ],
      "name": "generateReferralCode",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "user",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "points",
          "type": "uint256"
        }
      ],
      "name": "PointsAwarded",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "user",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "string",
          "name": "code",
          "type": "string"
        }
      ],
      "name": "ReferralCodeCreated",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "user",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "referrer",
          "type": "address"
        }
      ],
      "name": "ReferralSet",
      "type": "event"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "referrerReward",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "refereeReward",
          "type": "uint256"
        }
      ],
      "name": "setReferralCodeRewards",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "level1",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "level2",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "level3",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "level4",
          "type": "uint256"
        }
      ],
      "name": "setReferralRewardLevels",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "code",
          "type": "string"
        }
      ],
      "name": "setReferrer",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "newReward",
          "type": "uint256"
        }
      ],
      "name": "setStakerReward",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_yieldContract",
          "type": "address"
        }
      ],
      "name": "setYieldContract",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "user",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "points",
          "type": "uint256"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "referrer",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "level",
          "type": "uint256"
        }
      ],
      "name": "StakingPointsAwarded",
      "type": "event"
    },
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "name": "codeToAddress",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "user",
          "type": "address"
        }
      ],
      "name": "getPoints",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "owner",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "points",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "referralCodeRewardForReferee",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "referralCodeRewardForReferrer",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "referralCodes",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "referralRewardLevel1",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "referralRewardLevel2",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "referralRewardLevel3",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "referralRewardLevel4",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "referralRewards",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "referrers",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "stakerReward",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "yieldContract",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
  ], []);

  // Replace with your aUSD contract ABI
  const aUSDAbi = useMemo(() => [
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "initialSupply",
          "type": "uint256"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "spender",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "value",
          "type": "uint256"
        }
      ],
      "name": "Approval",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "Mint",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "value",
          "type": "uint256"
        }
      ],
      "name": "Transfer",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "account",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "bool",
          "name": "isWhitelisted",
          "type": "bool"
        }
      ],
      "name": "WhitelistStatusChanged",
      "type": "event"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "account",
          "type": "address"
        }
      ],
      "name": "addToWhitelist",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "spender",
          "type": "address"
        }
      ],
      "name": "allowance",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "spender",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "approve",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "account",
          "type": "address"
        }
      ],
      "name": "balanceOf",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "burn",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "decimals",
      "outputs": [
        {
          "internalType": "uint8",
          "name": "",
          "type": "uint8"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "spender",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "subtractedValue",
          "type": "uint256"
        }
      ],
      "name": "decreaseAllowance",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "spender",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "addedValue",
          "type": "uint256"
        }
      ],
      "name": "increaseAllowance",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "account",
          "type": "address"
        }
      ],
      "name": "isWhitelisted",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "mint",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "name",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "account",
          "type": "address"
        }
      ],
      "name": "removeFromWhitelist",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "symbol",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "totalSupply",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "recipient",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "transfer",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "sender",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "recipient",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "transferFrom",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    }
  ], []);

  
  // Check if the user is on the correct network (Arbitrum)
const checkNetwork = useCallback(async () => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const { chainId } = await provider.getNetwork();
    if (chainId === ARBITRUM_CHAIN_ID) {
      setIsCorrectNetwork(true);
    } else {
      setIsCorrectNetwork(false);
      messageApi.open({
        type: "warning",
        content: "Please switch to the Arbitrum network and refresh the page.",
        duration: 0,
        key: "network_warning",
      });
    }
  } catch (error) {
    console.error("Network check failed:", error);
    setIsCorrectNetwork(false);
  }
}, [messageApi]);

  // Fetch user's aUSD balance (retained for staking validation)
  const fetchAusdBalance = useCallback(async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const aUSDContract = new ethers.Contract(aUSDContractAddress, aUSDAbi, provider);
      const balance = await aUSDContract.balanceOf(address);
      setAusdBalance(ethers.utils.formatUnits(balance, 18));
    } catch (error) {
      console.error("Error fetching aUSD balance:", error);
    }
  }, [address, aUSDAbi]);

  // Function to fetch user's earned yield and base APR
  // Inside fetchYieldData
const fetchYieldData = useCallback(async () => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(stakingContractAddress, stakingContractAbi, provider);

    // Fetch the user's pending yield
    const userYield = await contract.pendingYield(address);
    const formattedYield = ethers.utils.formatUnits(userYield, 6); // Correct decimal for USDC

    console.log("Fetched yield:", formattedYield); // Verify the fetched yield
    setYieldEarned(formattedYield); // Set the state with correctly formatted yield

    // Fetch Base APR and average yield boost
    const fetchedApr = await contract.getAPR();
    const baseAprValue = parseFloat(fetchedApr.toString()) / 100;
    setBaseApr(baseAprValue);

    const fetchedAverageYieldBoost = await contract.getUserAverageYieldBoost(address);
    setAverageYieldBoost((parseFloat(fetchedAverageYieldBoost) / 100).toFixed(2));

  } catch (error) {
    console.error("Error fetching yield data:", error);
  }
}, [address, stakingContractAbi]);




  

  // Fetch boost multipliers from the contract
  const fetchBoostMultipliers = useCallback(async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(
        stakingContractAddress,
        stakingContractAbi,
        provider
      );

      const multiplier365 = await contract.boostMultiplier365();
      const multiplier180 = await contract.boostMultiplier180();
      const multiplier30 = await contract.boostMultiplier30();
      const defaultMultiplier = await contract.defaultMultiplier();

      setBoostMultipliers({
        365: parseFloat(multiplier365.toString()) / 100,
        180: parseFloat(multiplier180.toString()) / 100,
        30: parseFloat(multiplier30.toString()) / 100,
        0: parseFloat(defaultMultiplier.toString()) / 100,
      });
    } catch (error) {
      console.error("Error fetching boost multipliers:", error);
    }
  }, [stakingContractAbi]);

  // Fetch user points
  const fetchUserPoints = useCallback(async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      
      // Fetch points from AgioPoints contract
      const agioPointsContract = new ethers.Contract(
        agioPointsContractAddress,
        agioPointsAbi,
        provider
      );
      const userAgioPoints = await agioPointsContract.getPoints(address);
      
      // Fetch points from ManualAgioPointsAward contract
      const manualPointsContract = new ethers.Contract(
        manualAgioPointsAwardContractAddress,
        manualAgioPointsAbi,
        provider
      );
      const userManualPoints = await manualPointsContract.getPoints(address);
  
      // Combine both points
      const totalPoints = parseInt(userAgioPoints.toString()) + parseInt(userManualPoints.toString());
      
      setPoints(totalPoints);
    } catch (error) {
      console.error("Error fetching points:", error);
    }
  }, [address, agioPointsAbi, manualAgioPointsAbi, setPoints]);

  // Fetch user referral codes
  const fetchUserReferralCodes = useCallback(async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(
        agioPointsContractAddress,
        agioPointsAbi,
        provider
      );

      // Fetch user's own referral code
      const ownCode = await contract.referralCodes(address);
      if (ownCode && ownCode !== "") {
        setUserReferralCode(ownCode);
      } else {
        setUserReferralCode("");
      }

      // Fetch if user has used an external referral code
      const referrerAddress = await contract.referrers(address);
      if (referrerAddress !== ethers.constants.AddressZero) {
        const referrerCode = await contract.referralCodes(referrerAddress);
        setExternalReferralCodeUsed(referrerCode);
        setExternalReferralAddress(referrerAddress);
      } else {
        setExternalReferralCodeUsed("");
        setExternalReferralAddress("");
      }

      // Determine if referral is set
      if (ownCode !== "" || referrerAddress !== ethers.constants.AddressZero) {
        setIsReferralSet(true);
      } else {
        setIsReferralSet(false);
      }
    } catch (error) {
      console.error("Error fetching referral codes:", error);
    }
  }, [address, agioPointsAbi]);

  // Fetch total staked aUSD for the user using the new contract function
  const fetchUserTotalStaked = useCallback(async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(stakingContractAddress, stakingContractAbi, provider);

      const totalUserStaked = await contract.getUserTotalStaked(address);
      setTotalStaked(ethers.utils.formatUnits(totalUserStaked, 18));
    } catch (error) {
      console.error("Error fetching total staked balance for user:", error);
    }
  }, [address, stakingContractAbi]);

  // Fetch available to unstake (unlocked) balance
  const fetchAvailableToUnstake = useCallback(async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(stakingContractAddress, stakingContractAbi, provider);

      const availableToUnstake = await contract.getAvailableToUnstake(address);
      setStakedBalance(ethers.utils.formatUnits(availableToUnstake, 18));
    } catch (error) {
      console.error("Error fetching available to unstake balance:", error);
    }
  }, [address, stakingContractAbi]);

  // Call necessary functions when the component mounts or when dependencies change
  useEffect(() => {
    if (isConnected) {
      checkNetwork();
      fetchUserPoints();
      fetchUserReferralCodes();
      fetchAusdBalance();
      fetchUserTotalStaked();
      fetchAvailableToUnstake();
      fetchBoostMultipliers();
    }
  }, [
    isConnected,
    checkNetwork,
    fetchUserPoints,
    fetchUserReferralCodes,
    fetchAusdBalance,
    fetchUserTotalStaked,
    fetchAvailableToUnstake,
    fetchBoostMultipliers,
  ]);

  useEffect(() => {
    if (isConnected) {
      fetchYieldData();
    }
  }, [isConnected, fetchYieldData]);

  // Get reward multiplier based on lock duration
  const getRewardMultiplier = (duration) => {
    if (duration >= 365) {
      return boostMultipliers[365] || 1.5;
    } else if (duration >= 180) {
      return boostMultipliers[180] || 1.25;
    } else if (duration >= 30) {
      return boostMultipliers[30] || 1.1;
    } else {
      return boostMultipliers[0] || 1.0;
    }
  };

  useEffect(() => {
    const multiplierValue = getRewardMultiplier(lockDuration);
    setMultiplier(multiplierValue);

    // Calculate adjusted APR
    const newAdjustedApr = baseApr * multiplierValue;
    setAdjustedApr(newAdjustedApr);
  }, [lockDuration, boostMultipliers, baseApr]);

  // Calculate Agio Points based on staked amount and lock duration
const calculateAgioPoints = (amount, durationInDays) => {
  if (!amount || durationInDays < 7) return 0; // No points if staking for less than 7 days
  const points = (amount * durationInDays) / 7; // Example calculation for points
  return points.toFixed(2);
};

  // Handle claiming yield
  const handleClaimYield = async () => {
    try {
      if (!isCorrectNetwork) {
        throw new Error("You must be connected to the Sepolia network to claim yield.");
      }
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        stakingContractAddress,
        stakingContractAbi,
        signer
      );

      // Show yield claiming notification with steps
      notification.info({
        message: 'Claiming Yield',
        description: (
          <Steps current={1} size="small">
            <Step title="Initiating Claim" />
            <Step title="Claimed Successfully" />
          </Steps>
        ),
        placement: 'topRight',
        duration: 0,
        key: 'claim_yield_notification',
      });

      const transaction = await contract.claimYield();
      await transaction.wait();
      messageApi.success("Yield claimed successfully!");

      // Update the yield claiming notification to success
      notification.success({
        message: 'Yield Claimed',
        description: 'Your yield has been successfully claimed.',
        placement: 'topRight',
        duration: 3,
        key: 'claim_yield_notification',
      });

      // Refresh data
      fetchYieldData();
      fetchUserPoints();
    } catch (error) {
      console.error("Claiming yield failed:", error);
      messageApi.error("Claiming yield failed. Check the console for details.");

      // Update the yield claiming notification to error if exists
      notification.error({
        message: 'Claim Yield Failed',
        description: 'There was an error while claiming your yield.',
        placement: 'topRight',
        duration: 3,
        key: 'claim_yield_notification',
      });
    }
  };

  // Handle staking functionality
  const handleStake = async () => {
    try {
      if (!isCorrectNetwork) {
        throw new Error("You must be connected to the Sepolia network to stake.");
      }
      if (!stakeAmount || parseFloat(stakeAmount) <= 0) {
        throw new Error("Please enter a valid aUSD amount.");
      }
      if (parseFloat(stakeAmount) > parseFloat(ausdBalance)) {
        throw new Error("Insufficient aUSD balance.");
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const stakingContract = new ethers.Contract(
        stakingContractAddress,
        stakingContractAbi,
        signer
      );
      const aUSDContract = new ethers.Contract(aUSDContractAddress, aUSDAbi, signer);
      const approvalAmount = ethers.utils.parseUnits(stakeAmount, 18);

      if (!isApproved) {
        // Show approval notification with steps
        notification.info({
          message: 'Approval Transaction',
          description: (
            <Steps current={1} size="small">
              <Step title="Initiating Approval" />
              <Step title="Approval Successful" />
            </Steps>
          ),
          placement: 'topRight',
          duration: 0,
          key: 'approval_notification',
        });

        // Approve aUSD tokens for staking
        const approvalTx = await aUSDContract.approve(
          stakingContractAddress,
          approvalAmount
        );
        await approvalTx.wait();

        // Update approval status
        setIsApproved(true);
        messageApi.success("aUSD approved for staking.");

        // Update the approval notification to success
        notification.success({
          message: 'Approval Successful',
          description: 'aUSD has been approved. You can now proceed to stake.',
          placement: 'topRight',
          duration: 3,
          key: 'approval_notification',
        });

        return; // Exit to allow user to click Stake again
      }

      // Show staking notification with steps
      notification.info({
        message: 'Staking Transaction',
        description: (
          <Steps current={1} size="small">
            <Step title="Initiating Staking" />
            <Step title="Staked Successfully" />
          </Steps>
        ),
        placement: 'topRight',
        duration: 0,
        key: 'staking_notification',
      });

      // Stake aUSD tokens
      const stakeTransaction = await stakingContract.stake(
        approvalAmount,
        lockDuration * 24 * 60 * 60 // Convert days to seconds
      );
      await stakeTransaction.wait();
      messageApi.success("Staking successful!");

      // Update the staking notification to success
      notification.success({
        message: 'Staking Successful',
        description: 'Your aUSD has been staked successfully.',
        placement: 'topRight',
        duration: 3,
        key: 'staking_notification',
      });

      // Reset approval status
      setIsApproved(false);

      // Reset stake amount and lock duration
      setStakeAmount("");
      setLockDuration(0);

      // Refresh data
      fetchUserTotalStaked();
      fetchAvailableToUnstake();
      fetchYieldData();
      fetchUserPoints();
    } catch (error) {
      console.error("Staking failed:", error);
      messageApi.error("Staking failed. Check the console for details.");

      // Update the staking notification to error if exists
      notification.error({
        message: 'Staking Failed',
        description: 'There was an error while staking your aUSD.',
        placement: 'topRight',
        duration: 3,
        key: 'staking_notification',
      });
    }
  };

  // Handle unstaking functionality
  const handleUnstake = async () => {
    try {
      if (!isCorrectNetwork) {
        throw new Error("You must be connected to the Sepolia network to unstake.");
      }
      if (!unstakeAmount || parseFloat(unstakeAmount) <= 0) {
        throw new Error("Please enter a valid aUSD amount.");
      }
      if (parseFloat(unstakeAmount) > parseFloat(stakedBalance)) {
        throw new Error("Insufficient Balance");
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        stakingContractAddress,
        stakingContractAbi,
        signer
      );

      // Show unstaking notification with steps
      notification.info({
        message: 'Unstaking Transaction',
        description: (
          <Steps current={1} size="small">
            <Step title="Initiating Unstake" />
            <Step title="Unstaked Successfully" />
          </Steps>
        ),
        placement: 'topRight',
        duration: 0,
        key: 'unstaking_notification',
      });

      const transaction = await contract.unstake(
        ethers.utils.parseUnits(unstakeAmount, 18)
      );
      await transaction.wait();
      messageApi.success("Unstaking successful!");

      // Update the unstaking notification to success
      notification.success({
        message: 'Unstaking Successful',
        description: 'Your aUSD has been unstaked successfully.',
        placement: 'topRight',
        duration: 3,
        key: 'unstaking_notification',
      });

      // Reset unstake amount
      setUnstakeAmount("");

      // Refresh data
      fetchUserTotalStaked();
      fetchAvailableToUnstake();
      fetchYieldData();
      fetchUserPoints();
    } catch (error) {
      console.error("Unstaking failed:", error);
      // Check if the error is due to insufficient balance
      if (error.message.includes("Insufficient Balance")) {
        messageApi.error("Unstaking failed. You are trying to unstake more aUSD than what is unlocked.");
      } else {
        messageApi.error("Unstaking failed. Check the console for details.");
      }

      // Update the unstaking notification to error if exists
      notification.error({
        message: 'Unstaking Failed',
        description: 'There was an error while unstaking your aUSD.',
        placement: 'topRight',
        duration: 3,
        key: 'unstaking_notification',
      });
    }
  };

  // Handle generating referral code
  const handleGenerateReferralCode = async () => {
    try {
      if (!isCorrectNetwork) {
        throw new Error(
          "You must be connected to the Sepolia network to generate a referral code."
        );
      }
      if (!inputReferralCode) {
        throw new Error("Please enter a desired referral code.");
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const agioPointsContract = new ethers.Contract(
        agioPointsContractAddress,
        agioPointsAbi,
        signer
      );

      // Show referral code generation notification with steps
      notification.info({
        message: 'Generating Referral Code',
        description: (
          <Steps current={1} size="small">
            <Step title="Initiating Generation" />
            <Step title="Referral Code Generated" />
          </Steps>
        ),
        placement: 'topRight',
        duration: 0,
        key: 'referral_generation_notification',
      });

      const addressForCode = await agioPointsContract.codeToAddress(
        inputReferralCode
      );
      if (addressForCode !== ethers.constants.AddressZero) {
        messageApi.error("This referral code is already in use.");

        // Update the referral generation notification to error
        notification.error({
          message: 'Referral Code Generation Failed',
          description: 'The referral code you entered is already in use.',
          placement: 'topRight',
          duration: 3,
          key: 'referral_generation_notification',
        });

        return;
      }

      const generateReferralTx = await agioPointsContract.generateReferralCode(
        inputReferralCode
      );
      await generateReferralTx.wait();
      messageApi.success("Referral code generated successfully!");
      setUserReferralCode(inputReferralCode);
      setIsReferralSet(true);

      // Update the referral generation notification to success
      notification.success({
        message: 'Referral Code Generated',
        description: 'Your referral code has been successfully created.',
        placement: 'topRight',
        duration: 3,
        key: 'referral_generation_notification',
      });
    } catch (error) {
      console.error("Referral code generation failed:", error);
      messageApi.error(
        "Failed to generate referral code. Check the console for details."
      );

      // Update the referral generation notification to error if exists
      notification.error({
        message: 'Referral Code Generation Failed',
        description: 'There was an error while generating your referral code.',
        placement: 'topRight',
        duration: 3,
        key: 'referral_generation_notification',
      });
    }
  };

  // Handle using an external referral code
  const handleUseReferralCode = async () => {
    try {
      if (!isCorrectNetwork) {
        throw new Error(
          "You must be connected to the Sepolia network to use a referral code."
        );
      }
      if (!externalReferralCode) {
        throw new Error("Please enter a valid referral code.");
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const agioPointsContract = new ethers.Contract(
        agioPointsContractAddress,
        agioPointsAbi,
        signer
      );

      // Show referral code usage notification with steps
      notification.info({
        message: 'Using Referral Code',
        description: (
          <Steps current={1} size="small">
            <Step title="Initiating Usage" />
            <Step title="Referral Code Applied" />
          </Steps>
        ),
        placement: 'topRight',
        duration: 0,
        key: 'referral_usage_notification',
      });

      const setReferrerTx = await agioPointsContract.setReferrer(
        externalReferralCode
      );
      await setReferrerTx.wait();
      messageApi.success("Referral code used successfully!");
      setExternalReferralCode("");
      fetchUserReferralCodes(); // Refresh referral code status
      fetchUserPoints();

      // Update the referral usage notification to success
      notification.success({
        message: 'Referral Code Applied',
        description: 'Your referral code has been successfully set.',
        placement: 'topRight',
        duration: 3,
        key: 'referral_usage_notification',
      });
    } catch (error) {
      console.error("Referral failed:", error);
      messageApi.error("Failed to use referral code. Check the console for details.");

      // Update the referral usage notification to error if exists
      notification.error({
        message: 'Referral Code Usage Failed',
        description: 'There was an error while setting your referral code.',
        placement: 'topRight',
        duration: 3,
        key: 'referral_usage_notification',
      });
    }
  };

  // Handle updating pending yield
  const handleUpdatePendingYield = async () => {
    try {
      if (!isCorrectNetwork) {
        throw new Error("You must be connected to the Sepolia network to update yield.");
      }
      setIsUpdatingYield(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        stakingContractAddress,
        stakingContractAbi,
        signer
      );

      // Show updating yield notification with steps
      notification.info({
        message: 'Updating Yield',
        description: (
          <Steps current={1} size="small">
            <Step title="Initiating Update" />
            <Step title="Yield Updated" />
          </Steps>
        ),
        placement: 'topRight',
        duration: 0,
        key: 'update_yield_notification',
      });

      const tx = await contract.updatePendingYield();
      await tx.wait();
      messageApi.success("Yield updated successfully!");

      // Update the updating yield notification to success
      notification.success({
        message: 'Yield Updated',
        description: 'Your pending yield has been updated.',
        placement: 'topRight',
        duration: 3,
        key: 'update_yield_notification',
      });

      // Refresh yield data
      fetchYieldData();
    } catch (error) {
      console.error("Updating pending yield failed:", error);
      messageApi.error("Failed to update yield. Check the console for details.");

      // Update the updating yield notification to error if exists
      notification.error({
        message: 'Update Yield Failed',
        description: 'There was an error while updating your yield.',
        placement: 'topRight',
        duration: 3,
        key: 'update_yield_notification',
      });
    } finally {
      setIsUpdatingYield(false);
    }
  };

  // Determine the stake button text and disabled state
  const getStakeButtonState = () => {
    if (!stakeAmount || parseFloat(stakeAmount) <= 0) {
      return { text: "Enter Amount", disabled: true };
    } else if (parseFloat(stakeAmount) > parseFloat(ausdBalance)) {
      return { text: "Insufficient Balance", disabled: true };
    } else if (!isApproved) {
      return { text: "Approve", disabled: false };
    } else {
      return { text: "Stake Now", disabled: false };
    }
  };

  // Determine the unstake button text and disabled state
  const getUnstakeButtonState = () => {
    if (!unstakeAmount || parseFloat(unstakeAmount) <= 0) {
      return { text: "Enter Amount", disabled: true };
    } else if (parseFloat(unstakeAmount) > parseFloat(stakedBalance)) {
      return { text: "Insufficient Balance", disabled: true };
    } else {
      return { text: "Unstake Now", disabled: false };
    }
  };

  // Similar logic for Generate Code button
  const getGenerateCodeButtonState = () => {
    if (!inputReferralCode) {
      return { text: "Enter Code", disabled: true };
    } else {
      return { text: "Generate Code", disabled: false };
    }
  };

  // Similar logic for Apply Code button
  const getApplyCodeButtonState = () => {
    if (!externalReferralCode) {
      return { text: "Enter Code", disabled: true };
    } else {
      return { text: "Apply Code", disabled: false };
    }
  };

  // Uniswap URL for swapping USDC to aUSD
  const uniswapSwapUrl = `https://app.uniswap.org/#/swap?exactField=input&exactAmount=&inputCurrency=USDC&outputCurrency=0x28d5116b52ef0668aa75caf51f79840999dccddd`;

  return (
    <>
      {contextHolder}
      <div className="stakeContainer">
        <div className="stakeBox">
          {/* Toggle for Unstake and Earn Agio Points */}
          <div className="extraToggle">
            <div
              className={`toggleOption ${isUnstakeView ? "selected" : ""}`}
              onClick={() => {
                setIsUnstakeView(true);
                setIsStakeView(false);
                setIsReferView(false);
              }}
            >
              <span className="toggleIcon">🔓</span> Unstake
            </div>
            <div
              className={`toggleOption ${isReferView ? "selected" : ""}`}
              onClick={() => {
                setIsReferView(true);
                setIsStakeView(false);
                setIsUnstakeView(false);
              }}
            >
              <span className="toggleIcon">🎁</span> Earn Agio Points
              <Tooltip title="Earn Agio Points by referring others and staking aUSD!">
                <InfoCircleOutlined style={{ marginLeft: 5, color: "white" }} />
              </Tooltip>
            </div>
          </div>

          {/* Toggle for Stake and Buy aUSD */}
          <div className="stakeToggle">
            <div
              className={`toggleOption ${isStakeView ? "selected" : ""}`}
              onClick={() => {
                setIsStakeView(true);
                setIsReferView(false);
                setIsUnstakeView(false);
              }}
            >
              <span className="toggleIcon">💎</span> Stake
            </div>
            <a
              href={uniswapSwapUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="toggleOption"
            >
              <span className="toggleIcon">💲</span> Buy aUSD
            </a>
          </div>

          {/* Conditional rendering based on view */}
          {isStakeView && !isReferView && !isUnstakeView && (
            <div className="stakeSection">
              <Input
                placeholder="0.0 aUSD"
                value={stakeAmount}
                onChange={(e) => setStakeAmount(e.target.value)}
                className="amountInput"
              />
              <div className="durationAprContainer">
                <div className="lockDurationSection">
                  <div className="lockDurationLabel">
                    <label style={{ color: "white" }}>Lock Duration (days):</label>
                    <Tooltip title="The multiplier boosts your yield. Longer lock duration means higher yield.">
                      <InfoCircleOutlined style={{ marginLeft: 5, color: "white" }} />
                    </Tooltip>
                  </div>
                  <Slider
                    min={0}
                    max={365}
                    value={lockDuration}
                    step={1}
                    onChange={(value) => setLockDuration(value)}
                    tooltip={{
                      formatter: (value) =>
                        `${value} days | Multiplier: ${getRewardMultiplier(value)}x`,
                    }}
                    trackStyle={{ backgroundColor: "#00bfa6" }}
                    handleStyle={{ borderColor: "#00bfa6" }}
                  />
                </div>
                <div className="aprDisplay">
                  <label style={{ color: "white" }}>
                    Estimated APR:
                    <Tooltip title="Rewards are given in USDC">
                      <InfoCircleOutlined style={{ marginLeft: 5, color: "white" }} />
                    </Tooltip>
                  </label>
                  <div className="aprValue">
                    {adjustedApr.toFixed(2)}%
                    <img
                      src={usdcLogo}
                      alt="USDC Logo"
                      className="tokenLogoSmall"
                      style={{ marginLeft: 5 }}
                    />
                  </div>
                </div>
              </div>
              <Tooltip
                title={`You will earn approximately ${calculateAgioPoints(
                  parseFloat(stakeAmount),
                  lockDuration
                )} Agio Points by staking.`}
              >
                <Button
                  type="primary"
                  onClick={handleStake}
                  disabled={!isCorrectNetwork || getStakeButtonState().disabled}
                  className="actionButton"
                >
                  {getStakeButtonState().text}
                  {!getStakeButtonState().disabled && <ArrowRightOutlined />}
                </Button>
              </Tooltip>
            </div>
          )}

          {isUnstakeView && !isStakeView && !isReferView && (
            <div className="unstakeSection">
              <Input
                placeholder="0.0 aUSD"
                value={unstakeAmount}
                onChange={(e) => setUnstakeAmount(e.target.value)}
                className="amountInput"
              />
              {getUnstakeButtonState().text === "Insufficient Balance" ? (
                <Tooltip title="You are trying to unstake more aUSD than what is unlocked. Please wait for more time to unstake the remaining balance.">
                  <Button
                    type="primary"
                    onClick={handleUnstake}
                    disabled={!isCorrectNetwork || getUnstakeButtonState().disabled}
                    className="actionButton"
                  >
                    {getUnstakeButtonState().text}
                    {!getUnstakeButtonState().disabled && <ArrowRightOutlined />}
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  type="primary"
                  onClick={handleUnstake}
                  disabled={!isCorrectNetwork || getUnstakeButtonState().disabled}
                  className="actionButton"
                >
                  {getUnstakeButtonState().text}
                  {!getUnstakeButtonState().disabled && <ArrowRightOutlined />}
                </Button>
              )}
            </div>
          )}

          {isReferView && (
            <div className="referSection">
              <div className="generateReferralCode">
                <h3 className="referralText" style={{ color: "white" }}>
                  Your Referral Code:
                </h3>
                <div className="referralCodeBox">
                  {userReferralCode && (
                    <span>{userReferralCode}</span>
                  )}
                  {!userReferralCode && !externalReferralCodeUsed && "No code generated yet"}
                </div>
                {/* Display messages based on referral status */}
                {userReferralCode && (
                  <Tooltip title="You have your own referral code.">
                    <span style={{ color: "#00bfa6", marginTop: 5, display: 'block' }}>
                      Your referral code is already set.
                    </span>
                  </Tooltip>
                )}
                {!userReferralCode && (
                  <>
                    <Input
                      placeholder="Enter desired referral code"
                      value={inputReferralCode}
                      onChange={(e) => setInputReferralCode(e.target.value)}
                      className="referInput"
                    />
                    <Button
                      type="primary"
                      onClick={handleGenerateReferralCode}
                      disabled={
                        !isCorrectNetwork || getGenerateCodeButtonState().disabled
                      }
                      className="actionButton"
                    >
                      {getGenerateCodeButtonState().text}
                      {!getGenerateCodeButtonState().disabled && (
                        <ArrowRightOutlined />
                      )}
                    </Button>
                  </>
                )}
              </div>

              <div className="useReferralCode">
                <h3 className="lightGreyText" style={{ color: "white" }}>
                  Used Referral Code:
                </h3>
                <div className="referralCodeBox">
                  {externalReferralCodeUsed ? (
                    <span>{externalReferralCodeUsed}</span>
                  ) : (
                    "No referral code used yet."
                  )}
                </div>
                {!externalReferralCodeUsed && (
                  <>
                    <Input
                      placeholder="Enter a referral code"
                      value={externalReferralCode}
                      onChange={(e) => setExternalReferralCode(e.target.value)}
                      className="referInput"
                    />
                    <Button
                      type="primary"
                      onClick={handleUseReferralCode}
                      disabled={!isCorrectNetwork || getApplyCodeButtonState().disabled}
                      className="actionButton"
                    >
                      {getApplyCodeButtonState().text}
                      {!getApplyCodeButtonState().disabled && <ArrowRightOutlined />}
                    </Button>
                  </>
                )}
              </div>
            </div>
          )}
        </div>

        {/* Dashboard to display staked amount and interest earned */}
        {(parseFloat(totalStaked) > 0 || parseFloat(stakedBalance) > 0) && (
          <div className="stakedInfo">
            <h3 className="stakedInfoTitle">Dashboard</h3>

            {/* Total Staked Balance */}
            <div className="stakedAmount">
              <div className="stakedAmountHeader">
                <img
                  src={Logo}
                  alt="aUSD Logo"
                  className="tokenLogoSmall"
                />
                <span className="tokenSymbol">aUSD</span>
              </div>
              <div className="stakedAmountValue">
                <span>{parseFloat(totalStaked).toFixed(2)}</span>
              </div>
              <div className="stakedAmountNominal">
                <span>${parseFloat(totalStaked).toFixed(2)}</span>
              </div>
            </div>

            {/* Unlocked Staked Balance */}
            <div className="stakedAmount">
              <div className="stakedAmountHeader">
                <img
                  src={Logo}
                  alt="aUSD Logo"
                  className="tokenLogoSmall"
                />
                <span className="tokenSymbol">aUSD (Unlocked)</span>
              </div>
              <div className="stakedAmountValue">
                <span>{parseFloat(stakedBalance).toFixed(2)}</span>
              </div>
              <div className="stakedAmountNominal">
                <span>${parseFloat(stakedBalance).toFixed(2)}</span>
              </div>
            </div>

            {/* Interest Earned */}
            <div className="interestEarned">
              <div className="interestTitle">
                <span>Interest Earned</span>
                <Tooltip title="Update your pending yield">
                  <Button 
                    size="small" 
                    onClick={handleUpdatePendingYield} 
                    disabled={!isCorrectNetwork} 
                    loading={isUpdatingYield} 
                    icon={<ReloadOutlined />}
                    className="updateYieldButton"
                  />
                </Tooltip>
              </div>
              <div className="interestEarnedContent">
                <div className="interestEarnedHeader">
                  <img
                    src={usdcLogo}
                    alt="USDC Logo"
                    className="tokenLogoSmall"
                  />
                  <span className="tokenSymbol">USDC</span>
                </div>
                <div className="interestEarnedValue">
                  <span>{parseFloat(yieldEarned).toFixed(2)}</span>
                </div>
                <div className="interestEarnedNominal">
                  <span>${parseFloat(yieldEarned).toFixed(2)}</span>
                </div>
              </div>
              <Button
                type="primary"
                onClick={handleClaimYield}
                disabled={!isCorrectNetwork || parseFloat(yieldEarned) <= 0}
                className="actionButton"
              >
                Claim Interest <ArrowRightOutlined />
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Stake;
