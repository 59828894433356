import React, { useState, useEffect } from "react";
import { Input, message, Select } from "antd";
import { useSendTransaction, useWaitForTransaction } from "wagmi";
import { ethers } from 'ethers';
import tokenListuncover from "../tokenListuncover.json";

const { Option } = Select;

function Swap({ address, isConnected }) {
  const [messageApi, contextHolder] = message.useMessage();
  const [tokenOneAmount, setTokenOneAmount] = useState("");
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [cethBalance, setCethBalance] = useState(""); // cETH balance
  const [txDetails, setTxDetails] = useState({
    to: null,
    data: null,
    value: null,
  });

  const { data, sendTransaction } = useSendTransaction({
    request: {
      from: address,
      to: txDetails.to,
      data: txDetails.data,
      value: txDetails.value,
    },
  });

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  useEffect(() => {
    if (selectedAsset === 'cETH' && isConnected) {
      fetchCethBalance();
    }
  }, [selectedAsset, address, isConnected]);

  async function fetchCethBalance() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contractAddress = "0x16d5e1656a3F55a80Ced59b22D0CDBD91D83a8Ad"; // Updated contract address
    const contractAbi = [
      {
        "constant": true,
        "inputs": [{ "name": "account", "type": "address" }],
        "name": "balanceOf",
        "outputs": [{ "name": "", "type": "uint256" }],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      }
    ];
    const contract = new ethers.Contract(contractAddress, contractAbi, provider);
    const balance = await contract.balanceOf(address);
    setCethBalance(ethers.utils.formatEther(balance).slice(0, ethers.utils.formatEther(balance).indexOf('.') + 4)); // Shows only three decimal places
  }

  function changeAmount(e) {
    const newAmount = e.target.value;

    if (!/^\d*\.?\d*$/.test(newAmount)) {
      setTokenOneAmount("");
      return;
    }

    setTokenOneAmount(newAmount);
  }

  function handleAssetChange(value) {
    setSelectedAsset(value);
  }

  function isSwapButtonEnabled() {
    return !!tokenOneAmount && selectedAsset === 'cETH';
  }

  async function getRemainingLockTime(userAddress) {
    try {
      const contractAddress = "0x16d5e1656a3F55a80Ced59b22D0CDBD91D83a8Ad"; // Updated contract address
      const contractAbi = [
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "user",
              "type": "address"
            }
          ],
          "name": "getRemainingLockTime",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        }
      ];

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(contractAddress, contractAbi, provider);

      const remainingLockTime = await contract.getRemainingLockTime(userAddress);
      return remainingLockTime.toNumber();
    } catch (error) {
      console.error('Failed to get remaining lock time:', error);
      return null;
    }
  }

  async function swapCETHForEth() {
    try {
      if (!tokenOneAmount || parseFloat(tokenOneAmount) <= 0) {
        throw new Error("Please enter a valid CETH amount");
      }

      if (window.ethereum) {
        const contractAddress = "0x16d5e1656a3F55a80Ced59b22D0CDBD91D83a8Ad"; // Updated contract address
        const contractAbi = [
          {
            "inputs": [
              {
                "internalType": "uint256",
                "name": "cethAmount",
                "type": "uint256"
              }
            ],
            "name": "swapCETHForEth",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
          },
        ];

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, contractAbi, signer);

        const transaction = await contract.swapCETHForEth(ethers.utils.parseUnits(tokenOneAmount, 'ether'));
        await transaction.wait();

        message.success('Swap successful!');
      }
    } catch (error) {
      console.error('Swap failed:', error);

      if (error.message && error.message.includes("Not enough unlocked CETH")) {
        const remainingLockTime = await getRemainingLockTime(address);
        if (remainingLockTime !== null) {
          const hours = Math.floor(remainingLockTime / 3600);
          const minutes = Math.floor((remainingLockTime % 3600) / 60);
          const seconds = remainingLockTime % 60;
          message.error(`Swap failed. You will be able to redeem your cETH in ${hours}h ${minutes}m ${seconds}s.`);
        } else {
          message.error('Swap failed. Unable to determine remaining lock time.');
        }
      } else {
        message.error('Swap failed. Check the console for details.');
      }
    }
  }

  useEffect(() => {
    if (txDetails.to && isConnected) {
      sendTransaction();
    }
  }, [txDetails]);

  useEffect(() => {
    messageApi.destroy();

    if (isLoading) {
      messageApi.open({
        type: 'loading',
        content: 'Transaction is Pending...',
        duration: 0,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    messageApi.destroy();
    if (isSuccess) {
      messageApi.open({
        type: 'success',
        content: 'Transaction Successful',
        duration: 1.5,
      });
    } else if (txDetails.to) {
      messageApi.open({
        type: 'error',
        content: 'Transaction Failed',
        duration: 1.5,
      });
    }
  }, [isSuccess]);

  function renderOutputValue() {
    if (selectedAsset === 'cETH') {
      return `${tokenOneAmount} ETH`;
    } else if (selectedAsset === 'cAGIO') {
      const convertedAmount = tokenOneAmount;
      return `${convertedAmount} AGIO`;
    } else {
      return '';
    }
  }

  return (
    <>
      {contextHolder}
      <div className="tradeBox">
        <h2>Swap Covered Assets for Originals</h2>
        <div className="swapBox">
          <div className="balanceInfo">
            {selectedAsset === 'cETH' && cethBalance && (
              <div>
                Balance: {cethBalance} cETH
              </div>
            )}
          </div>
          <div className="inputWithTokenChoice">
            <Input
              placeholder="0.0"
              value={tokenOneAmount}
              onChange={changeAmount}
              className="amountInput"
            />
            <Select
              placeholder="Select Asset"
              onChange={handleAssetChange}
              value={selectedAsset}
              className="tokenselection"
            >
              {tokenListuncover.map((token) => (
                <Option key={token.address} value={token.ticker}>
                  <div className="tokenlogo">
                    <img src={token.img} alt={token.ticker} style={{ marginRight: 8, width: 20, height: 20 }} />
                    {token.ticker}
                  </div>
                </Option>
              ))}
            </Select>
          </div>
          <div className="switchButton">
            {/* Add your switch button content here */}
          </div>
          <div className="receiveAmount">
            <Input
              readOnly
              value={renderOutputValue()}
              style={{ marginBottom: 16 }}
            />
            <button className="swapButton" onClick={swapCETHForEth} disabled={!isSwapButtonEnabled()}>
              Redeem
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

function App() {
  const [address, setAddress] = useState(null);

  useEffect(() => {
    async function fetchAddress() {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const accounts = await provider.send('eth_requestAccounts', []);
        setAddress(accounts[0]);
      }
    }

    fetchAddress();
  }, []);

  return (
    <div className="App">
      {address ? (
        <Swap address={address} isConnected={!!address} />
      ) : (
        <button onClick={() => window.ethereum.request({ method: 'eth_requestAccounts' }).then(accounts => setAddress(accounts[0]))}>
          Connect to MetaMask
        </button>
      )}
    </div>
  );
}

export default App;
